import React, { useEffect } from "react";

import styled from "styled-components";
import HTMLReactParser from "html-react-parser";

import { black } from "theme/colors";
import { breakpoints, toRem } from "utils/mixins";

//STYLES
const Wrapper = styled.div`
  height: auto;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  .container {
    padding: 0 20px;
    margin: 110px auto 0;
    position: relative;
    h2 {
      margin: 0;
      margin-top: 40px;
      text-align: left;
      font-family: "Futura PT";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.2px;
      text-transform: uppercase;

      color: #398b59;
    }
    ul {
      margin-left: 30px;
      list-style: disc;
    }
    p,
    li {
      font-family: "Futura PT Book";
      font-style: normal;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.2px;

      color: ${black.default};
    }
    a {
      text-decoration: underline;
    }
  }

  @media (min-width: 767px) {
    .container {
      margin: ${toRem(130)} auto;
    }
  }
  @media (min-width: 1300px) {
    .container {
      margin: ${toRem(150)} auto;
    }
  }

  @media (min-width: 1400px) {
    .container {
      margin: ${toRem(170)} auto;
    }
  }

  @media (min-width: 1921px) {
    .container {
      margin: ${toRem(170)} 15%;
    }
  }
`;
//STYLES

const Policy = (props) => {
  //PROPS
  const {
    dimensions,
    setColor,
    data: {
      page: {
        policy: { textBlock }
      }
    }
  } = props;
  //PROPS

  //EFFECTS
  useEffect(() => {
    setColor(true);

    return () => {
      setColor(false);
    };
  }, [dimensions]);
  //EFFECTS

  return (
    <Wrapper>
      <div className="container">
        {textBlock.map((tBloc, index) => {
          return (
            <>
              <h2>{tBloc.title}</h2>
              {tBloc.text && HTMLReactParser(tBloc.text)}
            </>
          );
        })}
      </div>
    </Wrapper>
  );
};

export const query = graphql`
  query Policy($id: String) {
    page: wpPage(id: { eq: $id }) {
      policy {
        textBlock {
          text
          title
        }
      }
    }
  }
`;

export default Policy;
